<template>
    <form @submit="onSubmit" class="follow-up-form">
        <Field type="hidden" name="appointmentTypeId"></Field>

        <DatePickerField
            name="appointmentDate"
            class="fw-bold"
            label="Your Date"
            :date-picker-config="{
                autoApply: true,
                enableTimePicker: false,
                minDate: currentConsultation.consultation.createDateTime,
                preventMinMaxNavigation: true,
                format: 'dd MMM yyyy'
            }"
            mandatory
            :disabled="isSubmitting"
        ></DatePickerField>
        <DatePickerField
            name="startTime"
            class="fw-bold"
            label="Your Start Time"
            :date-picker-config="{
                autoApply: true,
                timePicker: true,
                is24: false,
            }"
            mandatory
            :disabled="isSubmitting"
            placeholder="Select Time"
            :hint="`Your timezone: ${consultantCountry ? formatTimezoneName(consultantCountry.timezones[0]) : ''}`"
            :errorMessage="errors.startTime"
            :warning="isClashing">
            <template #hint-prefix>
                <img
                    v-if="consultantCountry?.flag?.url"
                    :src="consultantCountry.flag.url"
                    :alt="`Flag of ${consultantCountry.name}`"
                    class="icon icon-m object-fit-contain flag-icon" />
            </template>
            <template #warning-slot>
                <ConsultantDayScheduleModal
                    :isClashing="isClashing"
                    :appointmentDate="values.appointmentDate"
                    :appointmentsForTheDayAsync="appointmentsForTheDayAsync">
                </ConsultantDayScheduleModal>
            </template>
        </DatePickerField>
        <TextField
            name="clientStartDateTime"
            class="fw-bold"
            :label="`${customerTerminologyName} Start Date Time`"
            mandatory
            disabled
            placeholder="Select Your Date and Start Time"
            :hint="clientRecord.timezone ? `${customerTerminologyName} timezone: ${formatTimezoneName(clientRecord.timezone)}` : ''"
        >
            <template #hint-prefix>
                <img 
                    v-if="clientRecord.country.flag"
                    :src="clientRecord.country.flag.url"
                    :alt="`Flag of ${clientRecord.country.name}`"
                    class="icon icon-m object-fit-contain flag-icon"
                />
            </template>
        </TextField>
        <template v-if="appointmentType.enableDurationPicker">
            <SelectionButtonsField
                name="duration"
                label="Duration"
                :items="appointmentDurations"
                item-text="name"
                item-value="totalMinutes"
                :column-count-md="2"
                mandatory
                :disabled="isSubmitting"
                class="appointment-duration-selections"
            ></SelectionButtonsField>
            <DatePickerField
                name="endTime"
                class="fw-bold"
                label="Your End Time"
                :date-picker-config="{
                    autoApply: true,
                    timePicker: true,
                    is24: false,
                    format: 'h:mm a'
                }"
                mandatory
                disabled
                placeholder="Select Start Time and Duration"
            ></DatePickerField>
            <TextField
                name="clientEndDateTime"
                class="fw-bold"
                :label="`${customerTerminologyName} End Date Time`"
                mandatory
                disabled
                placeholder="Select Your End Time"
                :hint="clientRecord.timezone ? `${customerTerminologyName} timezone: ${formatTimezoneName(clientRecord.timezone)}` : ''"
            ></TextField>
        </template>
        <RichTextEditorField
            name="notes"
            label="Notes"
            :disabled="isSubmitting"
        ></RichTextEditorField>

        <div class="d-flex justify-content-end">
            <ButtonWithSpinner 
                type="submit"
                :is-busy="isSubmitting"
                :disabled="isSubmitting"
                class="btn btn-dark">
                {{ getSubmitBtnText() }}
            </ButtonWithSpinner>
        </div>
    </form>
    <GenericModal modal-title="Unsaved appointments" ref="saveModal" >
        <template v-slot="{ hide }">
            <div class="px-4 pb-4 pt-2 vstack">
                <span class="fs-l">Are you sure you would like to continue without saving?</span>
                <div class="w-100 hstack gap-3 justify-content-end mt-4">
                    <button
                        @click="() => cancelToSave(hide)"
                        class="btn btn-outline-dark">
                        Cancel
                    </button>
                    <button
                        @click="continueWithoutSaving"
                        class="btn btn-primary text-dark fw-semibold px-4 py-2">
                        Continue
                    </button>
                </div>
            </div>
        </template>
    </GenericModal>
</template>

<script lang="ts" setup>
import { TextField, DatePickerField, ButtonWithSpinner, RichTextEditorField, SelectionButtonsField, GenericModal, Icon } from '@/modules/core/components';
import { useNotifications } from '@/modules/core/composables';
import { formatTimezoneName } from '@/modules/core/utilities';
import { usePortalConsultationStore, useCommonDataStore, usePortalAuthStore } from '@/modules/proportal/store';
import { storeToRefs } from 'pinia';
import { Field, useForm } from 'vee-validate';
import { computed, ref, toRef, watch } from 'vue';
import { AppointmentType, ConsultationRecord } from '@/modules/proportal/types';
import { FollowUpConsultationBookingFormFields } from './types';
import { followUpAppointmentSchema } from './schema';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import { useAppointmentsAsync } from '@/modules/proportal/datasources';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import ConsultantDayScheduleModal from '../../scheduling/consultant-day-schedule-modal.vue';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

interface Props {
    action?: 'Create'|'Edit';
    appointmentType: AppointmentType;
    currentConsultation: ConsultationRecord;
    appointmentId?: string;
    initialValues?: FollowUpConsultationBookingFormFields;
    refreshToggle?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    action: 'Create'
})

const router = useRouter();

const { consultation, client, clientRecord } = props.currentConsultation;

const emit = defineEmits<{
    (e: 'done'): void;
}>();

const isClashing = computed(() => {
    const schedules = appointmentsForTheDayAsync.value;
    const selectedStartDateTime = dayjs(values.appointmentDate)
        .set('hour', values.startTime?.hours ?? 0)
        .set('minutes', values.startTime?.minutes ?? 0);
    const selectedEndDateTime = values.endTime ? dayjs(values.appointmentDate)
        .set('hour', values.endTime?.hours)
        .set('minutes', values.endTime?.minutes) : selectedStartDateTime;
    if (schedules && schedules.length > 0) {
        return schedules[0].appointmentRecords.some((app) => {
            const appStartDateTime = dayjs(app.appointment.startDateTime);
            const appEndDateTime = dayjs(app.appointment.endDateTime);
            if (
                selectedStartDateTime.isBetween(appStartDateTime, appEndDateTime) ||
                selectedEndDateTime.isBetween(appStartDateTime, appEndDateTime) ||
                appStartDateTime.isBetween(selectedStartDateTime, selectedEndDateTime) ||
                appEndDateTime.isBetween(selectedStartDateTime, selectedEndDateTime) || 
                selectedStartDateTime.isSame(appStartDateTime) || 
                selectedStartDateTime.isSame(appEndDateTime)
            )
                return true;
        });
    } else {
        return false;
    }
});

const {
    handleSubmit,
    isSubmitting,
    values,
    setFieldValue,
    resetForm,
    errors,
    meta
} = useForm<FollowUpConsultationBookingFormFields>({
    validationSchema: followUpAppointmentSchema,
    initialValues: props.initialValues
});

const appointmentsForTheDayAsync = useAppointmentsAsync(
    computed(() => {
        return dayjs(values.appointmentDate).startOf('day').toDate()
    }),
    computed(() => {
        return dayjs(values.appointmentDate).endOf('day').toDate()
    }),
    toRef(props, 'refreshToggle'),
)

const commonDataStore = useCommonDataStore();
const { appointmentDurations,  } = storeToRefs(commonDataStore);
const consultationStore = usePortalConsultationStore();
const { customerTerminologyName, currentConsultationId } = storeToRefs(consultationStore);
const consultantStore = usePortalAuthStore()
const { consultant } = storeToRefs(consultantStore);

const consultantCountry = consultant.value?.country;

const notifications = useNotifications();

if (props.action === 'Create') {
    setFieldValue('appointmentDate', dayjs(consultation.createDateTime).add(props.appointmentType.recommendedDateRangeValue, props.appointmentType.recommendedDateRangeUnit).toDate());
}

watch(() => props.appointmentType, () => {
    if (props.action === 'Edit') return;

    setFieldValue('appointmentDate', dayjs(consultation.createDateTime).add(props.appointmentType.recommendedDateRangeValue, props.appointmentType.recommendedDateRangeUnit).toDate());
    setFieldValue('appointmentTypeId', props.appointmentType.key)
})

if (props.appointmentType.enableDurationPicker) {
    setFieldValue('durationPickerEnabled', true);
}
if (props.appointmentType) {
    setFieldValue('appointmentTypeId', props.appointmentType.key)
}

if (props.initialValues?.appointmentDate) {
    setFieldValue('appointmentDate', props.initialValues.appointmentDate)
}
if (props.initialValues?.duration) {
    setFieldValue('duration', props.initialValues.duration)
}

const updateEndTime = () => {
    if (values.startTime && values.duration) {
        const startDateTime = dayjs(values.appointmentDate)
            .set('hour', values.startTime.hours)
            .set('minutes', values.startTime.minutes);
        const endDateTime = startDateTime.add(values.duration, 'minutes');

        setFieldValue('endTime', {
            hours: endDateTime.get('hours'),
            minutes: endDateTime.get('minutes'),
            seconds: 0
        })
    }
}

const updateClientStartTime = () => {
    if(values.appointmentDate && values.startTime) {
        const startDateTime = dayjs(values.appointmentDate)
            .set('hour', values.startTime.hours)
            .set('minutes', values.startTime.minutes)
            .tz(clientRecord.timezone?.displayName)
            .format('DD MMM YYYY, hh:mm A');
        setFieldValue('clientStartDateTime', startDateTime);
    }
}

const updateClientEndTime = () => {
    if(values.appointmentDate && values.endTime) {
        const endDateTime = dayjs(values.appointmentDate)
            .set('hour', values.endTime.hours)
            .set('minutes', values.endTime.minutes)
            .tz(clientRecord.timezone?.displayName)
            .format('DD MMM YYYY, hh:mm A');
        setFieldValue('clientEndDateTime', endDateTime);
    }
}

watch(() => values.startTime, () => {
    updateEndTime();
})
watch(() => values.duration, () => updateEndTime())

watch(() => [values.appointmentDate, values.startTime], () => {
    updateClientStartTime();
}, { immediate: true })

watch(() => [values.appointmentDate, values.endTime], () => {
    updateClientEndTime();
}, { immediate: true })

const onSubmit = handleSubmit(
    async (values) => {
        const startDateTime = dayjs(values.appointmentDate)
            .set('hour', values.startTime.hours)
            .set('minutes', values.startTime.minutes);

        // const endDateTime = startDateTime.add(values.duration, 'minutes');
        
        const endDateTime = props.appointmentType.enableDurationPicker ? dayjs(values.appointmentDate)
            .set('hour', values.endTime.hours)
            .set('minutes', values.endTime.minutes) : startDateTime;

        const payload = {
            ...values,
            appointmentTypeId: values.appointmentTypeId,
            clientId: client.id,
            startDateTime: startDateTime.toISOString(),
            endDateTime: props.appointmentType.enableDurationPicker ? endDateTime.toISOString() : startDateTime.toISOString(),
            consultationId: consultation.id,
        }

        // console.log(payload)

        if (props.action === 'Create') {
            await consultationStore.createFollowUpAppointment(payload);
            notifications.showToast({
                message: 'Appointment created successfully',
                type: 'success'
            });
            resetForm();

        } else if (props.action === 'Edit') {
            if (!props.appointmentId) return;

            await consultationStore.editFollowUpAppointment(payload, props.appointmentId);
            notifications.showToast({
                message: 'Appointment updated successfully',
                type: 'success'
            });
        }

        consultationStore.getAppointmentsByConsultationId();
        emit('done');
    }
);

const getSubmitBtnText = () => {
    if (props.action === 'Create') {
        return 'Create'
    } else if (props.action === 'Edit') {
        return 'Update'
    }
}

const saveModal = ref();

const cancelToSave = (hide: () => void) => {
    hide();
    onSubmit();
}

const continueWithoutSaving = () => {
    saveModal.value?.hide();
    resetForm();
    router.push({
        name: 'consultation-finished',
        params: {
            consultationId: currentConsultationId.value,
        },
    });
};

onBeforeRouteLeave(async () => {
    if (props.action === 'Edit') {
        return true;
    } else {
        if (meta.value.dirty && values.startTime) {
            if (meta.value.valid) {
                await onSubmit();
                return true;
            } else {
                saveModal.value?.show();
                return false;
            }
        } else {
            return true;
        }
    }
});

</script>

<style lang="scss">
.follow-up-form {
    // .selection-button-item {
    //     padding-top: 1.25rem;
    //     padding-bottom: 1.25rem;
    // }
    .form-label {
        font-weight: bold;
    }
}
</style>